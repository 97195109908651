<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >身份证号在线生成</h2>
		<el-row style="margin-top: 4%;">
		<el-col :sm="11" style="margin: 0 auto; text-align: center;">
			<el-form :model="idCardData" >
				<el-form-item label="生成数量" label-width="70px">
						<el-col :sm = "24">
							<el-input v-model.number="idCardData.size" placeholder="请输入1-1000范围整数"></el-input>
						</el-col>
				</el-form-item>
				<el-row :gutter="5">
					<el-col :sm="8">
						<el-form-item label="出生地" label-width="70px">
						    <el-select v-model="idCardData.province" placeholder="所在省" @change="provincesClick()">
						      <el-option label="所在省" value=""></el-option>
							  <el-option v-for="(item,index) in idCardData.provinces" :key="index" :label="item" :value="index" ></el-option>
						    </el-select>
						  </el-form-item>
					</el-col>
					<el-col :sm="6">
						<el-form-item >
						    <el-select v-model="idCardData.city" placeholder="所在市" @change="citysClick()">
						      <el-option label="所在市" value=""></el-option>
							  <el-option v-for="(item,index) in idCardData.citys" :key="index" :label="item" :value="index" ></el-option>
						    </el-select>
						  </el-form-item>
					</el-col>
					<el-col :sm="6">
						<el-form-item >
						    <el-select v-model="idCardData.district" placeholder="所在地区">
						      <el-option label="所在地区" value=""></el-option>
							  <el-option v-for="(item,index) in idCardData.districts" :key="index" :label="item" :value="index" ></el-option>
						    </el-select>
						  </el-form-item>
					</el-col>
					
				</el-row>
				<el-row>
					<el-col :sm="8">
						<el-form-item label="出生日期" label-width="70px">
							<el-date-picker v-model="idCardData.birth"  type="date" placeholder="出生日期"> </el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :sm = "10">
						<el-form-item label="性别" label-width="70px">
							<el-radio-group v-model="idCardData.sex">
								<el-radio v-model="radio" label="1">男</el-radio>
								<el-radio v-model="radio" label="2">女</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				
				<el-form-item label="">
					<el-col :sm = "24">
						<el-input readonly  type="textarea" :rows="idCardData.rows" resize='none'  v-model="idCardData.text"></el-input>
					</el-col>
				</el-form-item>
				
				<div style="margin: 0 auto; text-align: center;">
					<el-button id="1001" type="primary" @click="generate()">生成</el-button>
					<el-button  @click="copy(idCardData.text)" type="primary" plain>复制</el-button>
				</div>
			</el-form>
	</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import {generate as idcardGenerate} from './idcard'
import {districts as idcardDistricts} from './idcard'
import {numberule as checknumber} from '../../common/validate'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'	
    let idCardData = ref({
		moduleid: 1004,
        size: 10,
        rows: 10,
		province: "",
		provinces: {},
		city: "",
		citys: {},
		district:"",
		districts: {},
		birth:"",
		sex:"",
        text: ""
    })
	
	moduleaction(idCardData.value.moduleid,1);
	idCardData.value.provinces=idcardDistricts(86);
	let provincesClick = () => {
		if(!idCardData.value.province){
			idCardData.value.city="";
			idCardData.value.district="";
		}
		idCardData.value.citys=idcardDistricts(idCardData.value.province)
		idCardData.value.city=Object.keys(idCardData.value.citys)[0]
		citysClick();
	}	
	let citysClick= () => {
		idCardData.value.districts=idcardDistricts(idCardData.value.city);
		idCardData.value.district=Object.keys(idCardData.value.districts)[0]
	}

	let generate = () => {
		var validresult = checknumber(idCardData.value.size,1000);
		if(!validresult.success){
			idCardData.value.text = validresult.info;
			return;
		}
		var area=idCardData.value.district
		var birth=idCardData.value.birth
		var sex=idCardData.value.sex
		idCardData.value.text = idcardGenerate(parseInt(idCardData.value.size),area,birth,sex).join("\n");
	}
//进入页面，触发数据生成逻辑
generate();	
</script>

<style>

</style>